import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { HeroIllustration } from "./HeroIllustration";
import { HeroAdressInput } from "./HeroAdressInput";
import { HeroValueProps } from "./HeroValueProps";
import MultiToggle from "elements/MultiToggle";

export const HeroSection = ({
	title,
	isTarget,
}: {
	title: string;
	isTarget?: boolean;
}) => {
	const [showToggle, setShowToggle] = useState(false);

	const pathname = usePathname();

	useEffect(() => {
		if (pathname == "/") {
			setShowToggle(true);
		} else {
			setShowToggle(false);
		}
	}, [pathname]);

	return (
		<div id="landing-hero-section">
			<HeroIllustration isTarget={isTarget} />
			<div id="address-property-type-toggle">
				{showToggle && <MultiToggle />}
				<HeroAdressInput title={title} />
			</div>
			<HeroValueProps />
		</div>
	);
};
