import NextImage, { StaticImageData } from "next/image";
import {
	FC,
	useMemo,
	isValidElement,
	Children,
	ReactNode,
	Fragment,
} from "react";
import Grid from "elements/Grid";
import CommercialBetterResults from "public/landing/Commercial/Commercial-Better-Results.png";
import CommercialBetterPricing from "public/landing/Commercial/Commercial-Better-Pricing.png";
import CommercialBetterService from "public/landing/Commercial/Commercial-Better-Service.png";
import HowItWorks1 from "public/landing/hiw1.png";
import HowItWorks2 from "public/landing/hiw2.png";
import HowItWorks3 from "public/landing/hiw3.png";
import Link from "next/link";
import { AbTest } from "elements/AbTest";

export const commercialValuePropItems = [
	{
		src: CommercialBetterService,
		title: {
			text: "Better Service",
			className: "landing-vp2-col-title-commercial",
		},
		body: (
			<>
				Sign up online in minutes, or send us a list of properties to evaluate.
				As soon as we receive an address or list of addresses, we'll start
				analyzing information to maximize tax savings.
			</>
		),
	},
	{
		src: CommercialBetterResults,
		title: {
			text: "Better Results",
			className: "landing-vp2-col-title-commercial",
		},
		body: (
			<>
				We'll develop our opinion of value based on property characteristics,
				market data, and other financial information provided, and represent
				each case from filing to finish.
			</>
		),
	},
	{
		src: CommercialBetterPricing,
		title: {
			text: "Better Pricing",
			className: "landing-vp2-col-title-commercial",
		},
		body: (
			<>
				Our rates are industry-leading, and there are no up-front costs to get
				started. We'll provide updates as soon as results are finalized.
			</>
		),
	},
];

export const defaultValuePropItems = [
	{
		src: HowItWorks1,
		title: { text: "Unlock your potential savings" },
		body: (
			<>
				Enter your property address and get an instant estimate of your
				potential tax savings based on local market data. Finish signing up for
				an appeal in less than 3 minutes. We never share or sell your
				information.
			</>
		),
	},
	{
		src: HowItWorks2,
		title: { text: "Sit back and let us do the work" },
		body: (
			<>
				Our local property tax experts are armed with cutting-edge technology to
				build the best case possible. Ownwell handles everything start to finish
				with no up-front costs. It’s all backed by our industry-leading{" "}
				<Link href="/pricing" className="color-azure">
					Savings-or-Free Guarantee
				</Link>
				.
			</>
		),
	},
	{
		src: HowItWorks3,
		title: { text: "Enjoy your savings" },
		body: (
			<>
				You pay a small contingency fee only if we save for you. If you don't
				save, you don't pay. We will continue to monitor your property taxes to
				ensure that you never overpay again. View our pricing{" "}
				<Link href="/pricing" className="color-azure">
					here
				</Link>
				.
			</>
		),
	},
];

export const landingPageBValuePropItems = [
	{
		src: HowItWorks1,
		title: { text: "Enter your address." },
		body: (
			<>
				Enter your address, and Ownwell can find you savings. We use real time
				market data, localized property tax expertise, and cutting-edge
				technology.
			</>
		),
	},
	{
		src: HowItWorks2,
		title: { text: "Answer some questions." },
		link: "/#landing-reviews-section",
		body: (
			<>
				There’s no upfront cost, it’s risk-free to sign up, and all backed by
				our industry-leading Savings-or-Free Guarantee. If you don't save our
				service is entirely free.
			</>
		),
	},
	{
		src: HowItWorks3,
		title: { text: "Unlock your savings." },
		body: (
			<>
				We gather evidence, file all documentation, and attend hearings on your
				behalf year after year, so you never overpay again.
			</>
		),
	},
];

export const newYorkTargettedValuePropItems = [
	{
		src: HowItWorks1,
		title: { text: "Get your savings estimate" },
		body: (
			<>
				Enter your property address and get an instant estimate of your
				potential tax savings based on local market data. Finish signing up for
				a grievance in less than 3 minutes. We never share or sell your
				information.
			</>
		),
	},
	{
		src: HowItWorks2,
		title: { text: "Sit back and let us do the work" },
		body: (
			<>
				Our local property tax experts are armed with cutting-edge technology to
				build the best case possible. Ownwell handles everything start to finish
				with no up-front costs. It’s all backed by our industry-leading{" "}
				<Link href="/pricing" className="color-azure">
					Savings-or-Free Guarantee
				</Link>
				.
			</>
		),
	},
	{
		src: HowItWorks3,
		title: { text: "Enjoy your savings" },
		body: (
			<>
				After we win your grievance, our fee is just 35% of your first year tax
				savings. If you don't save, you don't pay. We will continue to monitor
				your property taxes to ensure that you never overpay again.
			</>
		),
	},
];

export const targettedValuePropItems = [
	{
		src: HowItWorks1,
		title: { text: "Unlock your potential savings" },
		body: (
			<>
				Enter your property address and get an instant estimate of your
				potential tax savings based on local market data. Finish signing up for
				an appeal in less than 3 minutes. We never share or sell your
				information.
			</>
		),
	},
	{
		src: HowItWorks2,
		title: { text: "Sit back and let us do the work" },
		body: (
			<>
				Our local property tax experts are armed with cutting-edge technology to
				build the best case possible. Ownwell handles everything start to finish
				with no up-front costs. It’s all backed by our industry-leading{" "}
				<Link href="/pricing" className="color-azure">
					Savings-or-Free Guarantee
				</Link>
				.
			</>
		),
	},
	{
		src: HowItWorks3,
		title: { text: "Enjoy your savings" },
		body: (
			<>
				You pay a small contingency fee only if we save for you. If you don't
				save, you don't pay. We will continue to monitor your property taxes to
				ensure that you never overpay again. View our pricing{" "}
				<Link href="/pricing" className="color-azure">
					here
				</Link>
				.
			</>
		),
	},
];

export const partnerValuePropItems = [
	{
		src: HowItWorks1,
		title: { text: "View your potential savings" },
		body: (
			<>
				Enter your property address for an instant estimate of your potential
				tax savings based on local market data.
				{"\n\n"}
				Sign up for an appeal in less than 3 minutes. We will never share or
				sell your information.
			</>
		),
	},
	{
		src: HowItWorks2,
		title: { text: "Sit back and let us do the work" },
		body: (
			<>
				Our local property tax experts are armed with cutting-edge technology to
				build the best case possible.
				{"\n\n"}
				Ownwell handles everything start to finish with no up-front costs. It’s
				all backed by our industry-leading{" "}
				<Link href="/pricing" className="color-azure">
					Savings-or-Free Guarantee
				</Link>
				.
			</>
		),
	},
	{
		src: HowItWorks3,
		title: { text: "Enjoy your savings" },
		body: (
			<>
				Only pay if we save for you. If you don't save, you don't pay!
				{"\n\n"}
				We’ll continue to monitor your property taxes for years to come to
				ensure you never overpay again.
			</>
		),
	},
];

const ValueProps2Items = ({
	items,
}: {
	items: {
		src: StaticImageData;
		title: { text: string; className?: string };
		body: JSX.Element;
	}[];
}) => {
	return (
		<Grid>
			{items.map(({ title, body, src }, i) => (
				<div className="landing-vp2-col" key={title.text}>
					<div className="landing-vp2-img-container">
						<NextImage
							alt={title.text}
							src={src}
							style={{
								maxWidth: "100%",
								height: "auto",
							}}
						/>
						<div className="landing-vp2-step-counter">
							<h3>{i + 1}</h3>
						</div>
					</div>
					<h3 className={`landing-vp2-col-title ${title?.className ?? ""}`}>
						{title.text}
					</h3>
					<p className="landing-vp2-col-body">{body}</p>
				</div>
			))}
		</Grid>
	);
};

const ValueProps2 = ({ children }: { children: ReactNode }) => {
	return <div id="landing-vp2-section">{children}</div>;
};

ValueProps2.Items = ValueProps2Items;

export default ValueProps2;
