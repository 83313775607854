import PriceTag from "public/landing-hero/price-tag.svg";
import NoMoney from "public/landing-hero/no-money.svg";
import LocationPin from "public/landing-hero/location-pin.svg";

export const HeroValueProps = () => {
	return (
		<div id="landing-hero-value-props">
			<div className="landing-hero-value-prop">
				<PriceTag />
				<p className="azure bold lg">Only pay if you save</p>
			</div>
			<div className="landing-hero-value-prop">
				<NoMoney />
				<p className="azure bold lg">No upfront costs</p>
			</div>
			<div className="landing-hero-value-prop">
				<LocationPin />
				<p className="azure bold lg">Local tax experts</p>
			</div>
		</div>
	);
};
