import React, { useMemo } from "react";
import Image, { StaticImageData } from "next/image";
import partnerPages from "config/partner-pages.json";

// Partner Logos
import DoorvestLogo from "public/partners/doorvest.webp";
import RocketmoneyLogo from "public/partners/rocketmoney.png";
import HomeroomLogo from "public/partners/homeroom.png";
import AllTheHacksLogo from "public/partners/allthehacks.png";
import ValonLogo from "public/partners/valon.png";
import TurboTenantLogo from "public/partners/turbotenant.png";
import SfrNowLogo from "public/partners/sfr-now.png";
import AvenLogo from "public/partners/aven.png";
import PTMoneyLogo from "public/partners/ptmoney.png";
import LimaOneLogo from "public/partners/limaone.png";
import WalletHacks from "public/partners/wallethacks.png";
import TheCollegeInvestor from "public/partners/thecollegeinvestor.png";
import ExcaliburHomes from "public/partners/excalibur_homes.png";
import DabDoor from "public/partners/dabdoor.png";
import BanyanRidgeCapital from "public/partners/banyanridgecapital.png";
import Stessa from "public/partners/stessa.png";
import MoveConcierge from "public/partners/utilityconcierge.png";
import ArenaGroup from "public/partners/arenagroup.png";
import WRealty from "public/partners/wrealty.png";
import STR from "public/partners/str.png";
import TheDumbZone from "public/partners/thedumbzone.png";
import TheMomGame from "public/partners/themomgame.png";
import MichaelBordenaro from "public/partners/michaelbordenaro.png";
import HOM from "public/partners/hom.png";
import Haven from "public/partners/haven.png";
import DallasHomeShow from "public/partners/dallas-home-show.png";
import Hometap from "public/partners/hometap.png";

import { useRouter } from "next/router";

interface PartnersBannerProps {
	partnerName?: string;
}

export const PartnersBanner: React.FC<PartnersBannerProps> = ({
	partnerName,
}) => {
	const router = useRouter();

	const partnerKey = partnerName || (router?.query?.partner_page as string);

	const partnerRoute = useMemo(() => {
		if (!partnerKey) return undefined;

		const partnerPage = partnerPages[partnerKey as keyof typeof partnerPages];
		if (!partnerPage) return undefined;

		const partnerRouteLogoMap: {
			[key in keyof typeof partnerPages]: StaticImageData | null;
		} = {
			doorvest: DoorvestLogo,
			rocketmoney: RocketmoneyLogo,
			homeroom: HomeroomLogo,
			"taxreduction-cl": null,
			aplus: null,
			allthehacks: AllTheHacksLogo,
			valon: ValonLogo,
			turbotenant: TurboTenantLogo,
			sfrnow: SfrNowLogo,
			aven: AvenLogo,
			ptmoney: PTMoneyLogo,
			wrightpropertygroup: null,
			limaone: LimaOneLogo,
			wallethacks: WalletHacks,
			thecollegeinvestor: TheCollegeInvestor,
			excaliburhomes: ExcaliburHomes,
			banyanridgecapital: BanyanRidgeCapital,
			dabdoor: DabDoor,
			stessa: Stessa,
			moveconcierge: MoveConcierge,
			thearenagroup: ArenaGroup,
			wrealty: WRealty,
			str: STR,
			thedumbzone: TheDumbZone,
			themomgame: TheMomGame,
			thekeyresource: null,
			michaelbordenaro: MichaelBordenaro,
			hom: HOM,
			haven: Haven,
			"dallas-home-show": DallasHomeShow,
			"infinity-partners": null,
			hometap: Hometap,
		};

		return {
			...partnerPage,
			image: partnerRouteLogoMap[partnerKey as keyof typeof partnerPages],
		};
	}, [partnerKey]);

	if (!partnerRoute) return null;

	return (
		<div id="partners-referral-banner">
			<p className="semibold">In partnership with</p>
			<a
				href={partnerRoute.href?.trim() ? partnerRoute.href : undefined}
				target={partnerRoute.href?.trim() ? "_blank" : undefined}
				rel={partnerRoute.href?.trim() ? "noreferrer" : undefined}>
				{partnerRoute?.image ? (
					<Image
						src={partnerRoute.image}
						alt={partnerRoute.name}
						className={partnerRoute.name + "-partner-logo"}
					/>
				) : (
					<p className="lg partners-referral-display-name">
						{partnerRoute?.["display-name"]}
					</p>
				)}
			</a>
		</div>
	);
};
