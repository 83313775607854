import React from "react";
import Image from "next/image";
import { useOfferContext } from "context/OfferContext";
import realtorReferalPartners from "config/realtor-referral-partners.json";
import Label from "elements/Input/Label";

const RealtorReferralPartnerBanner = () => {
	const { offer } = useOfferContext();

	const realtorReferalPartnerConfig = React.useMemo(() => {
		if (!offer) return;
		return Object.values(realtorReferalPartners).find(partner =>
			partner.urls.includes(offer)
		);
	}, [offer]);

	if (!realtorReferalPartnerConfig) return null;

	const {
		name,
		photo,
		agency,
		phone,
	}: { name: string; photo?: string; agency: string; phone: string } =
		realtorReferalPartnerConfig;

	return (
		<div id="realtor-partner-referral-banner">
			<div className="grid">
				<div id="realtor-partner-referral-banner-profile">
					{photo && (
						<div id="realtor-partner-referal-banner-image-container">
							<Image
								width="100"
								height="100"
								src={photo}
								alt={`${name} Profile Photo`}
								quality={100}
								className="profile-photo"
							/>
						</div>
					)}
					<div>
						<Label>YOU'VE BEEN REFERRED BY:</Label>
						<p className="bold">{name}</p>
						<p className="sm">{agency}</p>
						<p className="sm">{phone}</p>
					</div>
				</div>
				<div id="realtor-partner-referral-banner-testimonial">
					<Label>WHY SIGN UP FOR OWNWELL?</Label>
					<p className="sm">
						Ownwell works hard to keep your property taxes as low as possible
						every year. Activate your Exemptions on day one and put your Appeals
						on auto-pilot.
					</p>
				</div>
			</div>
		</div>
	);
};

export default RealtorReferralPartnerBanner;
